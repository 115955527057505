.card-actions {
    padding: 8px 7px 0 6px;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.card-actions button,
.card-actions a {
    font-size: 26px;
    color: inherit;
    height: 35px;
    width: 35px;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-actions-right-side,
.card-actions-left-side {
    display: inline-flex;
}

.card-actions button:focus,
.card-actions a:focus {
    outline: none;
}

.add-to-collection-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 25px;
    border-top: none;
}

body .add-to-collection-modal .ant-modal-body {
    padding: 10px 20px 25px !important;
}

.add-to-collection-modal .collection-list > *:last-child > * {
    margin: 0;
}

.add-to-collection-modal .item-loader {
    min-height: 250px;
}

@media (min-width: 768px) {
    .card-actions {
        padding: 8px 13px 0 13px;
    }
}
.chats-block{
    border: 1px solid #E4E4E4;
    height: inherit;
    max-height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
}

.chats-block > *{
    width: 100%;
}

.chats-block-header{
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.chats-block-header .chat-create-popup-button{
    position: absolute;
    right: 15px;
    top: 13px;
}

.chats-block-header h1{
    font-size: 12px;
    margin: 0;
    color: inherit;
    font-weight: bold;
}

.chat-search-wrap{
    border-bottom: 1px solid #E4E4E4;
    padding: 11px 15px;
}

.actions-wrap{
    border-top: 1px solid #E4E4E4;
}

.chat-search-wrap,
.actions-wrap{
    max-height: 63px;
    height: 100%;
    display: flex;
    align-items: center;
}

.chat-list-wrap{
    height: 100%;
    max-height:inherit;
    overflow: auto;
}

@media (min-width: 768px) {
    .chat-search-wrap,
    .actions-wrap{
        padding: 11px 22px;
    }
}




.collection-card {
    margin-bottom: 10px;
}

.collection-card a {
    display: block;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.collection-card .card-data {
}

.collection-card .card-data h3 {
    font-size: 12px;
    line-height: 1.4em;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 0;
    min-height: 1.4em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.collection-card .card-data div {
    font-size: 12px;
    line-height: 1.4em;
}

.collection-card .cover-image,
.collection-card .cover-four-post {
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
}
.collection-card .cover-image > div,
.collection-card .cover-image img,
.collection-card .cover-post-list {
    position: absolute;
    flex-wrap: wrap;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.collection-card.empty .cover-image > div {
    align-items: center;
    justify-content: center;
    font-size: 8em;
    color: #dddddd;
}

.collection-card .cover-post-list li {
    width: 50%;
}

.collection-card.empty .cover-image {
}

.collection-card .cover-post-list li:nth-of-type(even) {
    border-left: 2px solid #dbdbdb;
}

.collection-card .cover-post-list li:nth-of-type(odd) {
    border-right: 2px solid #dbdbdb;
}

.collection-card .cover-post-list li:nth-of-type(1),
.collection-card .cover-post-list li:nth-of-type(2) {
    border-bottom: 2px solid #dbdbdb;
}

.collection-card .cover-post-list li:nth-of-type(3),
.collection-card .cover-post-list li:nth-of-type(4) {
    border-top: 2px solid #dbdbdb;
}

.collection-card .cover-post-list img {
    width: 100%;
}

.collection-card .card-data span + span {
    margin-left: 5px;
}

.collection-card .private {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    z-index: 10;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
    .collection-card .card-data h3 {
        margin-top: 10px;
        font-size: 14px;
    }

    .collection-card .card-data div {
        font-size: 14px;
    }

    .collection-card {
        margin-bottom: 25px;
    }
}

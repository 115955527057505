.chat-message-action-modal .ant-modal-content{
    box-shadow: initial;
    overflow: initial !important;
}

.chat-message-action-modal .ant-modal-body{
    max-height: initial !important;
    overflow: initial !important;
}

.chat-message-action-modal .chat-message-content-wrap{
    max-width: initial !important;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 11px;
}

.chat-message-action-modal .chat-message-content{
    background-color: transparent !important;
    border: initial !important;

}

.chat-message-action-modal .chat-message-content div:first-child{
    max-height: 130px;
    overflow: hidden;
    white-space: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.chat-message-action-modal .ant-modal-content{
    background-color: transparent !important;
}

.chat-message-action-modal .message-content{
    margin-bottom: 10px;
}

.chat-message-action-modal .message-actions-wrap{
    background-color: #FFFFFF;
    border-radius: 11px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.chat-message-action-modal .message-actions-wrap button{
    background: transparent !important;
}

.chat-message-action-modal .delete-action button{
    font-weight: bold;
}
.change-forgot-password-form .form-actions{
    display: flex;
    justify-content: center;
}

.change-forgot-message{
    font-size: 14px;
    line-height: 1.5em;
    padding: 15px 0;
    text-align: center;
}

.change-forgot-message .item-loader{
    margin-top: 20px;
}

.change-forgot-message .error-text{
    font-size: 16px;
    padding: 25px 0;
}
.modal-attachments-list {
}

.modal-attachments-list.modal-actions .ant-modal {
    width: 600px !important;
}

.modal-attachments-list.modal-actions .attachments-list {
    padding: 20px 10px 30px;
}

.attachments-list {
    padding: 0;
    margin: 0px 0px;
    list-style: none;
}

.attachments-list li {
    font-size: 13px;
    line-height: 1.4em;
    font-weight: bold;
    color: inherit;
    justify-content: space-between;
    display: flex;
}

.attachments-list li + li {
    margin-top: 20px;
}

.attachments-list li a {
}

.attachments-list .download-file {
    display: flex;
    align-items: center;
    min-width: 1px;
    flex: 1;
}

.attachments-list .download-file-info {
    min-width: 1px;
    flex: 1;
}

.attachments-list .download-file-author {
    font-size: 13px;
    line-height: 1em;
    margin-bottom: 3px;
}

.attachments-list .download-file-author a {
    display: block;
    font-weight: normal;
}

.attachments-list .download-file-author .anticon {
    font-size: 10px !important;
    margin-right: 3px !important;
}

.attachments-list .download-file-name {
    display: flex;
    align-items: center;
    flex: 1;
}

.attachments-list .download-file-name span:not(.file-extension) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attachments-list li .download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: 50px;
    margin-left: 20px;
    border-radius: 20px;
    font-size: 20px;
    color: #676767;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.attachments-list li .download-button span {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
}

.attachments-list .download-file .anticon {
    margin-right: 12px;
    font-size: 17px;
}

.attachments-list .actions {
    display: flex;
    align-items: center;
}

.attachments-list .post-link {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.attachments-list .post-link span {
    font-size: 12px;
    font-weight: normal;
    margin-left: 10px;
}

.attachments-list .download-file-created,
.attachments-list .download-file-description {
    font-size: 11px;
    font-weight: 400;
}

.attachments-list .download-file-created {
    padding: 0 10px;
}

@media screen and (max-width: 767px) {
    .modal-attachments-list.modal-actions .ant-modal {
        max-width: 100% !important;
    }
}

@media (min-width: 768px) {
    .attachments-list li {
        display: flex;
        font-size: 14px;
    }

    .attachments-list .download-file .anticon {
        font-size: 27px;
    }

    .attachments-list li .download-button {
        font-size: 22px;
        min-width: 57px;
    }

    .modal-attachments-list .attachments-list {
        margin: 22px 20px;
    }

    .attachments-list .download-file-created,
    .attachments-list .download-file-description {
        font-size: 13px;
    }

    .attachments-list .post-link span {
        font-size: 14px;
    }

    .modal-attachments-list.modal-actions .attachments-list {
        padding: 0;
    }

    .attachments-list li .download-button span {
        font-size: 14px;
    }
}

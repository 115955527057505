.collection {
    min-height: 300px;
}

.collection .collection-posts-list {
    margin-bottom: 50px;
    min-height: 320px;
}

.collection .collection-posts-list .card-list {
    padding: 10px 0 0;
}

.collection .empty-collection {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.collection-posts-actions {
    padding: 10px 0;
}

.collection-posts-actions .posts-view-type {
    display: flex;
}

.top-app-bar .collection-header-small-but {
    font-size: 24px;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

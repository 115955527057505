.chat-messages-block{
    height: inherit;
    max-height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.chat-messages-header,
.chat-message-action-wrap{
    padding: 10px 20px;
    min-height: 54px;
}

.chat-message-action-wrap{
    border-top: 1px solid #E4E4E4;
    transition: all ease-out 0.3s;
}

.chat-message-action-wrap.with-message{
    min-height: auto;
}

.chat-message-action-wrap.with-message .chat-message-action-forms-wrap{
    height: initial;
}

.chat-message-action-wrap.with-message .chat-message-reply{
    margin-bottom: 10px;
    height: 32px;
}

.chat-message-action-wrap .chat-message-reply{

}

.chat-message-action-forms-wrap{
    display: flex;
    align-items: center;
    height: 100%;
}

.chat-message-action-forms-wrap > *:first-child{
    margin-right: 5px;
}

.chat-messages-header{
    max-height: 54px;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.chat-message-list-wrap{
    height: inherit;
    max-height:inherit;
    overflow: auto;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.chat-message-list-wrap .item-loader{
    height: 100%;
}

@media (max-width: 767px) {
    .chat-messages-header .ant-avatar {
        width: 35px !important;
        height: 35px !important;
        line-height: 35px !important;
    }

    .chat-messages-header .ant-avatar > *{
        line-height: inherit !important;
    }
}

@media (min-width: 768px) {
    .chat-messages-header {
        max-height: 64px;
    }

    .chat-messages-header,
    .chat-message-action-wrap{
        min-height: 64px;
    }
}
button.follow-button {
    border-radius: 20px;
    min-width: 100px;
    min-height: 30px;
    color:#A2A2A2;
    font-size: 12px;
    font-weight: 700;
    border: none;
}

button.follow-button:hover {
    opacity: 0.8;
}

@media (min-width: 768px) {
    button.follow-button {
        font-size: 16px;
        min-width: 128px;
        font-weight: 600;
        min-height: 40px;
    }
}

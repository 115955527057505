.hashtags-list {
    margin-top: -22px;
}

.hashtags-list .hashtag-row {
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.hashtags-list .hashtag-row .count-posts {
    font-size: 13px;
    font-weight: bold;
}

.hashtags-list .hashtag-row a:hover .avatar-data,
.hashtags-list .hashtag-row a:hover .avatar-label {
    color: inherit;
}

.hashtags-list .hashtag-row .card-data {
    flex: 1 1;
    display: flex;
    min-width: 1px;
    margin-right: 20px;
    max-width: 80%;
    padding: 0;
}

@media (min-width: 768px) {
    .hashtags-list {
        margin-top: 0;
    }

    .hashtags-list .hashtag-row {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.chat-create-selected-users{
    padding: 20px 20px 0px;
    font-size: 12px;
    font-weight: bold;
}

.chat-create-selected-users.empty{
    margin-bottom: 7px;
}

.chat-create-selected-users .label{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
}

.chat-create-selected-users .list{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.chat-create-selected-users .list li{
    margin-right: 5px;
    margin-bottom: 7px;
}

.chat-create-selected-users .list button{
    cursor: pointer;
    border: none;
    font-weight: bold;
    border-radius: 12px;
    padding: 2px 10px;
    outline: none;

}
.collection-update {
    min-height: 300px;
}

.collection-update h2 {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    font-size: 26px;
}

.collection-update .ant-input {
    border-radius: 8px;
}

.collection-update .ant-upload .ant-avatar {
    cursor: pointer;
}

.collection-update .avatar-photo .ant-avatar {
    border-radius: 9px;
}

.collection-update .collection-status-block {
    margin-bottom: 0;
}

.collection-status-block .ant-form-item-control {
    line-height: 1em;
}

.collection-update .ant-form-item-label {
    padding-bottom: 0;
}

.collection-update .collection-cover {
    margin: 15px 0 5px;
}

.collection-data-form .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.collection-data-form .form-actions > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.collection-data-form .form-actions > div > * + * {
    margin-left: 20px;
}

.collection-update .site-label-text-color {
    margin-bottom: 5px;
}

.collection-update .ant-form label {
    font-size: 12px;
}

@media (max-width: 767px) {
    .collection-update .ant-avatar-square {
        width: 66px !important;
        height: 66px !important;
    }

    .collection-update .collection-status-block {
        padding: 10px 0 20px;
    }

    .collection-update button.rounded-button {
        font-size: 13px;
    }
}

@media (min-width: 768px) {
    .collection-data-form .form-actions > div {
        width: auto;

    }

    .collection-update .ant-form-item {
        margin-bottom: 15px;
    }

    .collection-update h2 {
        display: flex;
    }

    .collection-update .site-label-text-color {
        margin-bottom: 10px;
    }

    .collection-update .collection-cover {
        margin: 40px 0 30px;
    }

    .collection-update .ant-form label {
        font-size: 14px;
    }
}

.notification-count .ant-badge-count{
    min-width: 11px;
    font-size: 8px;
    line-height: 14px;
    height: 14px;
    padding: 0 5px  ;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: translate(6px, -1px);
    -ms-transform: translate(6px, -1px);
    transform: translate(6px, -1px);
}

.notification-button .label{
    margin-left: 12px !important;
}

.notification-count .anticon{
    background: none;
}

.notification-count .ant-scroll-number-only,
.notification-count .ant-scroll-number-only > p{
    height: inherit;
}

.notification-button{
    position: relative;
}

.notification-badge{
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    left: 20px;
    top: 1px;
    border-radius: 50%;
}
.avatar {
    display: flex;
    flex: 1;
    min-width: 1px;
}

.avatar .anticon-loading {
    font-size: 1.5em;
}

.avatar.big-badge {
    flex: inherit;
}

.avatar.big-badge .ant-avatar-square {
    border-radius: 9px;
}

.avatar .avatar-photo {
    margin-right: 11px;
}

.avatar .avatar-photo.empty .ant-avatar {
    border-style: dashed;
    border-width: 1px;
}

.avatar .avatar-wrap {
    position: relative;
}

.avatar .avatar-badge {
    position: absolute;
    width: 18px;
    height: 18px;
    right: -8px;
    bottom: -6px;
    line-height: 1em;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px #fff;
    color: #fff;
}

.avatar.big-badge .avatar-badge {
    width: 24px;
    height: 24px;
    font-size: 14px;
    right: -12px;
    bottom: -7px;
    box-shadow: 0 0 0 2px #fff;
}

.avatar .avatar-photo a {
    display: block;
}

.avatar-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1px;
    flex: 1 1;
}

.avatar-data .avatar-label a,
.avatar-data .avatar-label {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2em;
}

.avatar-data.with-link .avatar-action a,
.avatar-data.with-link .avatar-label a,
.avatar-data.with-link .avatar-label {
    margin-bottom: 0;
}

.avatar-data.with-link .avatar-action a,
.avatar-data .avatar-text {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.4em;
}

.avatar-data.with-link .avatar-action {
    line-height: 1em;
}

.avatar-data.date .avatar-text {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.avatar-data.profile .avatar-label {
    color: inherit;
    font-size: 18px;
    font-weight: 400;
}

.avatar-data .avatar-action button {
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.3s;
}

.upload-avatar-but .anticon-loading {
    margin-left: 10px;
}

.avatar.three-row .avatar-photo {
    margin-right: 16px;
}

.avatar.three-row .title {
    font-size: 13px;
    line-height: 1.3em;
    font-weight: bold;
    margin: 0 0 4px;
}

.avatar.three-row .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    font-size: 12px;
}

.avatar.three-row .author .anticon {
    font-size: 10px;
    margin-right: 3px;
}

.avatar.three-row .author {
    font-size: 11px;
    line-height: 1em;
    margin-bottom: 3px;
}

.avatar.three-row .avatar-data {
    padding: 0;
}

.avatar .avatar-action .author {
    font-size: 11px;
    display: flex;
    align-items: center;
    line-height: 1em;
}

.avatar .avatar-action .author span {
    margin-left: 3px;
}

.avatar .avatar-action .author .anticon {
    font-size: 10px;
}

.avatar-blocked{

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.avatar-blocked .avatar{
    font-size: 38px;
    color: #FFF;
    background-color: #D5D7D8;
    border-radius: 50%;
    border: 3px solid #D5D7D8;
    overflow: hidden;
    flex: initial;
    min-width: initial;
}

.avatar-blocked .avatar > *{
    transform: translate(0, 13%);
}

.avatar-blocked .cross{
    position: absolute;
    background-color: #A2A2A2;
    border-radius: 50%;
    font-size: 8px;
    color: #FFF;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
}

.avatar-blocked.big .avatar{
    font-size: 100px;
    border-width: 6px;
}

.avatar-blocked.big .cross{
    font-size: 15px;
    padding: 7px;
}

@media (max-width: 767px) {
    .avatar.big-badge .ant-avatar-square {
        width: 66px !important;
        height: 66px !important;
    }

    .avatar.big-badge .ant-avatar-square span {
        line-height: 66px !important;
    }

    .avatar.three-row .text {
        white-space: inherit;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .avatar-data .avatar-label .avatar-link{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }
}

@media (min-width: 768px) {
    .avatar-data .avatar-action button {
        font-size: 14px;
    }

    .avatar-blocked.big .avatar{
        font-size: 150px;
        border-width: 6px;
    }

    .avatar-blocked.big .cross{
        font-size: 25px;
        padding: 10px;
    }

    .avatar .avatar-photo {
        margin-right: 18px;
    }

    .avatar.big-badge .avatar-badge {
        width: 50px;
        height: 50px;
        font-size: 32px;
        right: -25px;
        bottom: -18px;
        box-shadow: 0 0 0 2px #fff;
    }

    .avatar .avatar-action .author {
        font-size: 13px;
    }

    .avatar.three-row .title {
        font-size: 14px;
        line-height: 1.4em;
    }

    .avatar.three-row .text {
        font-size: 13px;
    }

    .avatar.three-row .avatar-photo {
        margin-right: 20px;
    }

    .avatar-data .avatar-label a,
    .avatar-data .avatar-label {
        font-size: 13px;
        line-height: 1.4em;
    }

    .avatar-data.with-link .avatar-action a,
    .avatar-data .avatar-text {
        font-size: 13px;
    }

    .avatar.three-row .author {
        font-size: 13px;
    }
}

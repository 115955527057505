.following-tabs .ant-tabs-bar {
    border: none;
    margin: 10px 0 40px 0;
}

.following-tabs {
    min-height: 300px;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-ink-bar {
    top: -1px;
    background-color: #333333;
    height: 1px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    width: 90px;
}

.following-tabs .ant-tabs-nav-container {
    overflow: visible;
}

.following-tabs .ant-tabs-nav-scroll,
.following-tabs .ant-tabs-nav-wrap {
    overflow: inherit;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-tab {
    border: none !important;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-tab,
.following-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: transparent !important;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-tab {
    font-size: 10px;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-tab span {
    display: block;
    line-height: 1;
    align-items: center;
}

.following-tabs .ant-tabs-card-bar .ant-tabs-tab .anticon {
    display: block;
    font-size: 28px;
    margin-right: 0;
}

.following-tab {
    min-height: 350px;
}

.following-tab > *,
.following-tab > * > * {
    min-height: inherit;
}

@media (min-width: 768px) {
    .following-tabs .ant-tabs-card-bar .ant-tabs-tab span {
        display: flex;
        line-height: inherit;
        align-items: center;
    }

    .following-tabs .ant-tabs-card-bar .ant-tabs-tab {
        font-size: 14px;
    }

    .following-tabs .ant-tabs-card-bar .ant-tabs-tab .anticon {
        display: inline-block;
        margin-right: 8px;
    }

    .following-tabs .ant-tabs-bar {
        margin: 0 0 30px 0;
    }

    .following-tabs .ant-tabs-card-bar .ant-tabs-ink-bar {
        visibility: visible !important;
    }
}

.chat-create-modal .search-form{
    padding: 0 20px 20px;
}

.chat-create-modal .search-result-list li {
    padding-left: 20px;
    padding-right: 20px;
}

.chat-create-modal.modal-actions .ant-modal{
    max-width: 100% !important;
}

.chat-create-modal.modal-actions .ant-modal-body{
    height: calc( calc(var(--vh, 1vh) * 100)  - 75px );
}

.chat-create-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 12px;
}

.chat-create-popup-button{
    min-width: 170px !important;
}

.chat-create-popup-button.short{
    min-width: initial !important;
    padding: 0;
    width: 28px;
    height: 28px;
    color: inherit !important;
    background-color: transparent !important;
    box-shadow: none;
}

.chat-create-popup-button .label {
    font-size: 14px;
    margin-left: 12px !important;
    margin-top: -2px;
}

.chat-create-popup-button .anticon{
    font-size: 24px;
}

@media (max-width: 767px) {
    .chat-create-modal .ant-modal-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #FFFFFF;
    }

    .chat-create-modal.modal-actions .ant-modal-body{
        margin-bottom: 24px;
    }
}

@media (min-width: 768px) {
    .chat-create-modal.modal-actions .ant-modal{
        max-width: 430px !important;
    }

    .chat-create-modal.modal-actions .ant-modal-body{
        max-height: 500px;
    }
}
.top-app-bar{
    height: 50px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border-bottom: 1px solid #E4E4E4;
}

.top-app-bar-left,
.top-app-bar-right{
    min-width: 50px;
}

.top-app-bar-right{
    display: flex;
    justify-content: flex-end;
}

.top-app-bar-title{
    flex: 1;
    display: flex;
    justify-content: center;
    text-align: center;
}

.top-app-bar-title h3,
.top-app-bar-title{
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.3em;
    color: inherit;
}

.top-app-bar .back-button {
    position: relative;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: none;
}
.create-collection-button {
    min-width: 146px;
    border: none;
    height: 30px;
    padding: 0 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s;
    outline: none;
}

.create-collection-button .anticon {
    font-size: 10px;
}

.create-collection-button .label {
    font-size: 12px;
    line-height: 1em;
    margin-left: 10px;
    font-weight: bold;
}

.create-collection-modal .create-collection-form {
    padding: 28px 24px 31px;
}

.create-collection-modal .site-label-text-color {
    font-size: 13px;
}

.create-collection-modal input[type="text"] {
    height: 40px;
    border-color: #dddddd;
    box-shadow: initial;
}

.create-collection-form-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .create-collection-button {
        min-width: 200px;
        height: 40px;
    }

    .create-collection-button .label {
        font-size: 14px;
        line-height: 1em;
        font-weight: bold;
    }

    .create-collection-button .anticon {
        font-size: 12px;
    }

    .create-collection-modal .ant-modal-content {
        min-width: 450px;
    }
}

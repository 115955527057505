.user-tabs {
    margin-bottom: 50px;
    padding-top: 0;
    border-top: 1px solid #e4e4e4;
    min-height: 300px;
}

.user-tabs .card-data {
    margin-bottom: 0;
}

.user-tabs .item-loader {
    min-height: 100px;
}

.user-tabs .ant-tabs-bar {
    border-bottom: none !important;
    padding: 8px 0 23px;
    margin: 0;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-ink-bar {
    display: none !important;
    visibility: visible !important;
    top: -1px;
    background-color: #333333;
    height: 1px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    width: 90px;
}

.user-tabs .ant-tabs-nav-container {
    display: flex;
    justify-content: space-between;
    overflow: visible;
}

.user-tabs .ant-tabs-nav-scroll,
.user-tabs .ant-tabs-nav-wrap {
    overflow: inherit;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-nav-wrap {
    display: flex;
    justify-content: left;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab {
    border: none !important;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab,
.user-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: transparent !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    padding: 0;
    height: 30px;
    line-height: 30px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 30px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 40px;
}

.user-tabs .ant-tabs-nav > div {
    display: flex;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab {
    font-size: 10px;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab .anticon {
    display: block;
    font-size: 28px;
    margin-right: 0;
    margin-bottom: -9px;
}

.user-tab {
    min-height: 250px;
}

.user-tab .user-documents-list .download-file .anticon {
    font-size: 27px;
}

.user-tab .user-documents-list .download-file-created {
    margin-right: auto;
    padding: 0 10px 0 0;
}

.user-tabs-message {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.user-tabs-message .no-content-icon{
    margin-bottom: 10px;
}

.user-tab .card-list {
    border-top: 1px solid #dddddd;
    margin-top: 0 !important;
    padding: 0;
}

.user-tab .posts-list-wrap .item-loader {
    min-height: 100px;
}

.user-tab .user-documents-list .download-button {
    min-width: 111px;
    height: 26px;
    font-size: 16px;
}

.user-tab .user-documents-list .download-button span {
    font-size: 12px;
}

.user-tab .user-documents-list li {
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    padding: 10px;
    margin-top: 0;
}

.user-tab .documents-sorting {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.user-tab .documents-sorting .ant-radio-button-wrapper {
    padding: 0 40px;
}

.documents-sorting .ant-radio-button-wrapper-checked:focus-within,
.documents-sorting .ant-radio-button-wrapper-checked:focus {
    outline: none;
}

.user-tab .documents-sorting .ant-radio-button-wrapper {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    box-shadow: none;
}

.user-tab .documents-sorting .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.user-tab .user-documents-list .download-file {
    margin-bottom: 7px;
}

.user-tab .user-documents-list .post-link span {
    margin-left: 5px;
}

.user-tab .create-collection-button-holder .create-collection-button {
    min-width: 285px;
    margin: 0 auto 10px;
}

.user-tab .posts-view-type {
    max-width: 304px;
    margin: 0 auto 10px;
}

.user-tab .card-list.grid {
    padding: 10px 0 0;
}

.user-tab .card-preview-row-holder:first-child .card-preview-row {
    padding-top: 10px;
}

@media (min-width: 768px) {
    .user-tab .posts-view-type {
        position: absolute;
        right: 0;
        top: 10px;
        margin: 0;
    }

    .user-tabs .ant-tabs-card-bar .ant-tabs-tab {
        font-size: 14px;
    }

    .user-tabs .ant-tabs-card-bar .ant-tabs-tab .anticon {
        font-size: 26px;
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 0;
    }

    .user-tabs .ant-tabs-card-bar .ant-tabs-tab span {
        display: flex;
        align-items: center;
    }

    .user-tabs .ant-tabs-nav-container {
        display: block;
    }

    .user-tabs .ant-tabs-bar {
        padding: 15px 0;
    }

    .user-tab .card-list {
        padding: 0;
    }

    .user-tab {
        min-height: 300px;
    }

    .user-tab .card-preview-row-holder:first-child .card-preview-row {
        padding-top: 20px;
    }

    .user-tab .create-collection-button-holder {
        position: absolute;
        top: 16px;
        right: 0;
    }

    .user-tab .create-collection-button-holder .create-collection-button {
        min-width: 200px;
        margin: 0;
    }

    .user-tab .documents-sorting {
        position: absolute;
        top: 21px;
        right: 0;
    }

    .user-tab .documents-sorting .ant-radio-button-wrapper {
        font-size: 16px;
    }

    .user-tab .documents-sorting .ant-radio-button-wrapper {
        padding: 0 15px;
    }

    .user-tab .user-documents-list .download-file {
        margin-bottom: 0;
    }

    .user-tab .user-documents-list .post-link span {
        margin-left: 10px;
    }

    .user-tab .user-documents-list .download-button {
        min-width: 157px;
        height: 40px;
        font-size: 19px;
    }

    .user-tab .user-documents-list .download-button span {
        font-size: 14px;
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 20px;
    }

    .user-tab .user-documents-list li {
        padding: 10px 0;
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .user-tab .posts-view-type {
        top: 15px;
    }

    .user-tabs .ant-tabs-card-bar .ant-tabs-tab {
        font-size: 16px;
    }

    .user-tabs .ant-tabs-card-bar .ant-tabs-tab .anticon {
        font-size: 28px;
        margin-right: 6px;
    }

    .user-tabs .ant-tabs-bar {
        padding: 21px 0 23px;
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 40px;
    }

    .user-tab .user-documents-list li {
        padding: 17px 0 20px;
        display: flex;
    }

    .user-tab .user-documents-list .download-file-created {
        margin-right: 0;
        padding: 0 10px;
    }
}

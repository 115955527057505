.chat-search-form {
    width: 100%;
}

.chat-search-form .input-bg-color {
    border-radius: 20px;
    overflow: hidden;
}

.chat-search-form .search-field {
    display: flex;
    align-items: center;
}

.chat-search-form .search-field .clear-search {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    outline: none;
    padding: 0;
    box-shadow: none;
}

.chat-search-form .search-field .ant-input {
    margin-bottom: 0;
    box-shadow: none;
    font-size: 13px;
    outline: 0;
    border: 0;
    min-height: 30px;
    background-color: transparent;
    height: 30px;
    padding: 7px 20px 8px 40px;
}

.chat-search-form .search-field .ant-input-prefix {
    left: 10px;
}

.chat-search-form .search-field .anticon {
    font-size: 24px;
}

@media (max-width: 767px) {

    .chat-search-form .search-field .clear-search {
        height: 30px;
    }

    .chat-search-form .search-field .ant-input{
        line-height: 30px;
    }

}

@media (min-width: 768px) {
    .chat-search-form {
        padding: 0;
    }

    .chat-search-form .search-field .ant-input {
        margin-bottom: 0;
        box-shadow: none;
        font-size: 14px;
        min-height: 40px;
        height: 40px;
        padding: 10px 20px 11px 55px;
    }

    .chat-search-form .search-field .ant-input-prefix {
        left: 20px;
    }

    .chat-search-form .search-field .clear-search {
        font-size: 14px;
        margin-left: 10px;
    }
}


.users-list .user-row {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.search-results .posts-view-type {
    position: relative;
}

.search-results .posts-view-type::before {
    content: "";
    position: absolute;
    left: -9999px;
    right: -9999px;
    bottom: -10px;
    height: 1px;
    background-color: #dddddd;
}

.search-results .attachments-list li {
    margin: 0;
}

.search-results .card-preview-row-holder:first-child .card-preview-row {
    padding-top: 20px;
}

.users-list .user-row a:hover .avatar-data,
.users-list .user-row a:hover .avatar-label {
    color: inherit;
}

.search-documents-list .download-file .anticon {
    font-size: 27px;
}

.search-documents-list .download-file-author {
    font-size: 11px;
}

.search-documents-list .download-file-created {
    margin-right: auto;
    padding: 0 10px 0 0;
}

.search-results .search-documents-list .download-button {
    min-width: 111px;
    height: 26px;
    font-size: 16px;
}

.search-documents-list li {
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    padding: 10px;
    margin-top: 0;
}

.search-documents-list .download-file {
    margin-bottom: 7px;
}

.search-documents-list .post-link span {
    margin-left: 5px;
}

@media (min-width: 768px) {

    .search-documents-list .download-file .anticon{
        font-size: 32px;
    }

    .search-documents-list .post-link span {
        margin-left: 10px;
    }

    .search-documents-list .download-file-author {
        font-size: 13px;
    }

    .search-results .search-documents-list .download-button {
        min-width: 157px;
        height: 40px;
        font-size: 19px;
    }

    .search-documents-list .download-button span {
        font-size: 14px;
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 20px;
    }

    .users-list .user-row,
    .search-documents-list li {
        padding: 10px 0;
        margin-bottom: 0;
    }

    .search-results .posts-view-type::before {
        display: none;
    }
}

@media (min-width: 1024px) {
    .search-documents-list .download-file {
        margin-bottom: 0;
    }

    .users-list .user-row,
    .search-documents-list li {
        padding: 17px 0 20px;
        display: flex;
    }

    .search-documents-list .download-file-created {
        margin-right: 0;
        padding: 0 10px;
    }
    .users-list {
        padding: 0;
    }
}

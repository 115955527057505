.follow-list-wrap {
    padding: 15px 0;
    max-height: 300px;
}

.follow-collection-list {
    padding: 0 10px;
    list-style: none;
    min-width: 300px;
}

.follow-collection-list li {
    padding: 10px 24px;
    display: flex;
}

.following-page .follow-collection-list li {
    padding: 10px 0;
}

.follow-collection-list li:first-child {
    padding-top: 0;
}

.follow-collection-list li:last-child {
    padding-bottom: 0;
}

@media (min-width: 768px) {
    .follow-collection-list {
        padding: 0;
    }
}

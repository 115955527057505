.post-create h2 {
    font-size: 16px;
    margin-bottom: 17px;
    text-align: center;
}

@media (max-width: 767px) {
    .post-create h2 {
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .post-create-form h2 {
        font-size: 26px;
        margin-bottom: 30px;
    }
}
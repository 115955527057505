.post-update-media .ant-upload-list-item-thumbnail{
    opacity: 1;
}

.post-update-media .ant-upload-select-picture-card:before,
.post-update-media .ant-upload-list-picture-card .ant-upload-list-item:before{
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    content: "";
}

.post-update-media .ant-upload-picture-card-wrapper{
    width: calc(100% + 10px);
    margin: 0 -4px;
}

.post-update-media .ant-upload-select-picture-card .ant-upload,
.post-update-media .ant-upload-list-item-info{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.post-update-media .ant-upload-select-picture-card{
    display: block;
}

.post-update-media .ant-upload-select-picture-card .ant-upload{
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-update-media .upload-label{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .post-update-media .ant-upload-picture-card-wrapper{
        width: calc(100% + 14px);
        margin: 0 -7px;
    }
}
.collection-card-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.collection-card-row .card-action {
    display: flex;
    align-items: center;
    min-width: 20px;
    justify-content: center;
}

.collection-card-row .card-action button {
    cursor: pointer;
}

.collection-card-row .card-data {
    flex: 1;
    padding: 0 20px;
}

.collection-card-row .card-post-count {
    display: flex;
    align-items: center;
}

.collection-card-row .public,
.collection-card-row .private {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    font-size: 15px;
}

@media (min-width: 768px) {
    .collection-card-row .public,
    .collection-card-row .private {
        font-size: 18px;
    }

    .collection-card-row {
        margin-bottom: 20px;
    }
}

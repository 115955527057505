.chat-message-list{
    margin: 0;
    padding: 20px 0;
    list-style: none;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100%;
}

.chat-message-wrap{
    margin: 0;
    padding: 3px 24px;
}
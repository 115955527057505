.share-post-modal .ant-modal-body {
    max-height: 450px;
    min-height: 450px;
    overflow: auto;
}

.share-post-modal .ant-modal-title h3 {
    font-weight: bold;
    text-align: center;
    margin: 0;
    font-size: 18px;
}

.share-post-modal .search-form {
    padding: 20px 10px;
}

.share-post-modal .search-result-list li {
    padding-left: 10px;
    padding-right: 10px;
}

.share-post-modal .search-result-list li {
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .share-post-modal .search-form {
        padding: 20px;
    }

    .share-post-modal .search-result-list li {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 767px) {
    .share-post-modal .ant-modal-body{
        height: 100vh;
    }
}

.hashtag-timeline-posts {
    margin-top: 29px;
}

.hashtag-timeline-posts .item-loader {
    height: 100px;
}

.hashtag-timeline-posts .card-list {
    padding: 0;
}

.hashtag-timeline-posts .card-list .card-timeline-holder {
    margin-bottom: 15px;
}

.hashtag-timeline {
    justify-content: center;
}

@media (min-width: 768px) {
    .hashtag-timeline-posts .card-list .card-timeline-holder {
        margin-bottom: 0;
    }

    .hashtag-timeline-posts {
        margin-top: 0;
    }
}

.forgot-password-form .form-actions{
    display: flex;
    justify-content: center;
}

.forgot-password-form .ant-form-item-required span{
    float: left;
    line-height: 1.5em;
}

.forgot-password-form .ant-form-item-required::before {
    margin-left: 2px;
}

.forgot-form-message{
    text-align: center;
    padding: 36px 0 37px;
    font-size: 16px;
    line-height: 1.5em;
}
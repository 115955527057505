.chat-wrap .avatar-label{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-wrap .avatar-text{
    font-size: 12px;
    white-space: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chat-wrap .avatar-photo {
    margin-right: 10px;
}

.chat-messages-block-wrap{
    border: 1px solid #E4E4E4;
    height: inherit;
    max-height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.empty-chat-wrap{
    margin-top: -15%;
    font-size: 13px;
    line-height: 1.4em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.empty-chat-wrap .anticon{
    font-size: 150px;
    margin-bottom: 12px;
}

.chat-wrap > *:last-child{
    background: #FFF;

}

@media (max-width: 767px) {
    .chat{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    .chat-wrap > *:last-child{
        position: absolute;
        z-index: 3;
        right: -100%;
        transition: all ease-out 0.3s;
    }

    .chat-wrap.chat-open > *:last-child{
        right: 0;
    }
}
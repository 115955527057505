.collection-header h1 {
    font: 26px/1.2em 'Titillium Web', sans-serif;
    margin: 0;
    color: #333;
}

.collection-header .ant-avatar-string {
    font-size: 2.6em;
}

.collection-header h2 {
    font: 700 12px/1.2em 'Titillium Web', sans-serif;
    margin-bottom: 4px;
}

.collection-header-author,
.collection-header-status {
    margin-bottom: 20px;
    font-size: 14px;
    margin-top: 5px;
}

.collection-header-author .anticon,
.collection-header-status .anticon {
    font-size: 17px;
}

.collection-header-author a {
    display: flex;
    align-items: center;
}

.collection-header-status .site-label-text-color {
    display: flex;
    align-items: center;
}

.collection-header-author .site-label-text-color span,
.collection-header-status .site-label-text-color span {
    margin-left: 5px;
    font-weight: bold;
    line-height: 1em;
}

.collection-header-photo {
    display: flex;
    justify-content: center;
}

.collection-header-data {
    display: flex;
    align-items: center;
}

.collection-header-data .ant-btn-circle {
    border: none;
    background-color: transparent;
    font-size: 28px;
    display: none;
    align-items: center;
    justify-content: center;
}

.collection-header-actions {
    display: flex;
    align-items: center;
}

.collection-header-statistics {
    display: flex;
    font-size: 14px;
    flex-direction: row;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
}

.collection-header-statistics li + li {
    margin-left: 40px;
}

.collection-header-statistics a {
    color: inherit;
}

.collection-header-description {
    white-space: pre-line;
    font-size: 12px;
    line-height: 1.4;
}

.collection-header-about {
    padding: 11px 10px;
    border-top: 1px solid #e9ebee;
}

@media (min-width: 768px) {
    .collection-header-about {
        padding: 20px 0;
        border: none;
    }

    .collection-header h2 {
        font: 700 14px/1.2em 'Titillium Web', sans-serif;
    }

    .collection-header-statistics a,
    .collection-header-statistics a b {
        -webkit-transition: all ease-out 0.25s;
        -moz-transition: all ease-out 0.25s;
        -o-transition: all ease-out 0.25s;
        transition: all ease-out 0.25s;
    }

    .collection-header-description {
        white-space: pre-line;
        font-size: 14px;
        line-height: 1.5;
    }

    .collection-header-data .ant-btn-circle {
        display: flex;
    }
}

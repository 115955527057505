.profile-form .ant-form-explain,
.profile-form .ant-form-extra {
    font-size: 11px;
    margin-top: 0;
    margin-left: 3px;
}

@media (min-width: 768px) {
    .profile-form .ant-form-explain,
    .profile-form .ant-form-extra {
        font-size: 13px;
    }
}

.chat-wrap > * > *,
.chat-wrap > *,
.chat-wrap{
    height: calc( 100vh - 190px );
    max-height: calc( 100vh - 190px );
}

@media (max-width: 767px) {
    .chat-wrap > *,
    .chat-wrap > * > *,
    .chat-wrap {
        height: calc( calc(var(--vh, 1vh) * 100)  - 52px )  !important;
        max-height: calc( calc(var(--vh, 1vh) * 100)  - 52px ) !important;
    }
}
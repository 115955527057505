.collection-timeline-posts .item-loader {
    height: 100px;
}

.collection-timeline-posts .card-timeline-holder {
    margin-bottom: 15px;
}

.collection-timeline {
    justify-content: center;
}

.collection-timeline.card-list {
    padding: 0;
}

@media (min-width: 768px) {
    .collection-timeline-posts .card-timeline-holder {
        margin-bottom: 0;
    }
}

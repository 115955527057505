.follow-hashtag-list{
    padding: 0 15px;
    list-style: none;
    min-width: 300px;
}

.follow-hashtag-list li{
    padding: 10px 24px;
    display: flex;
}

.following-page .follow-hashtag-list li{
    padding: 10px 0;
}

.follow-hashtag-list li:first-child{
    padding-top: 0;
}

.follow-hashtag-list li:last-child{
    padding-bottom: 0;
}

@media (min-width: 768px) {
    .follow-hashtag-list{
        padding: 0;
    }
}
.post-update-drawer h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
}

.post-update-drawer .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
}

.post-update-drawer .ant-drawer-body {
    padding: 20px 12px;
}

.post-update-drawer .ant-drawer-header {
    padding: 14px 10px;
}

.post-update-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
}

.post-update-drawer .ant-upload-list-item-name {
    color: #666666;
}

.post-update-drawer .ant-upload-drag-container > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-update-drawer .ant-upload-drag-container .anticon-loading {
    font-size: 18px;
}

.post-update-drawer .ant-upload-list {
    margin-top: 50px;
}

.post-update-drawer .ant-upload-drag-container span {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
}

.post-update-drawer .upload-rules {
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
}

.post-update-drawer .ant-drawer-title {
    position: relative;
}

.post-update-drawer .close-drawer {
    font-size: 12px;
    border: none;
    background-color: transparent;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.post-update-drawer .hide-drawer {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.post-update-attachments-wrap h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-update-attachments-wrap .attachment-title-but {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

@media (min-width: 768px) {
    .post-update-drawer .ant-drawer-header {
        padding: 18px 24px 19px;
    }

    .post-update-drawer .ant-drawer-body {
        padding: 30px 20px;
    }

    .post-update-drawer .ant-drawer-content-wrapper {
        width: 500px !important;
    }

    .post-update-drawer .upload-rules {
        font-size: 13px;
        top: 75px;
    }

    .post-update-drawer .hide-drawer {
        margin-top: 40px;
    }
}

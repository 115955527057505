.manage-images-drawer h4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.manage-images-drawer .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}

.manage-images-drawer .ant-drawer-body {
  padding: 0;
}

.manage-images-drawer .ant-drawer-header {
  padding: 14px 10px;
}

.manage-images-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}

.manage-images-drawer .ant-upload-list-item-name {
  color: #666666;
}

.manage-images-drawer .ant-upload-drag-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-images-drawer .ant-upload-drag-container .anticon-loading {
  font-size: 18px;
}

.manage-images-drawer .ant-upload-list {
  margin-top: 50px;
}

.manage-images-drawer .ant-upload-drag-container span {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.manage-images-drawer .upload-rules {
  font-size: 11px;
  text-align: center;
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
}

.manage-images-drawer .ant-drawer-title {
  position: relative;
}

.manage-images-drawer .close-drawer {
  font-size: 12px;
  border: none;
  background-color: transparent;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.manage-images-drawer .hide-drawer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.post-manage-images-wrap h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-manage-images-wrap .rounded-button {
  float: right;
  margin-top: 23px;
}

.sortable-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sortable-list-tab {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  padding: 20px 15px 20px 35px;
  background: white;
  border-bottom: 1px solid #ddd;
  cursor: default;
  visibility: visible;
  z-index: 99999999;
  list-style-type: none;
}

.sortable-list-tab  .anticon-menu {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  cursor: grab;
}

.sortable-list-tab-drag {
  box-shadow: 0px 3px 9px 5px rgba(0,0,0,.1);
}

.sortable-list-tab img {
  max-width: 100%;
  height: auto;
}

.sortable-list-tab .post-update-media h4 {
  display: none;
}

.sortable-list-tab .ant-upload-list-picture-card-container {
  width: 100%;
}

.sortable-list-tab .ant-upload-list {
  margin-top: 0;
}

.sortable-list-tab .box-img {
  position: relative;
}

.sortable-list-tab .box-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  opacity: 0;
}

.sortable-list-tab .box-img:hover i {
  opacity: 1;
}

.sortable-list .form-actions {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .manage-images-drawer .ant-drawer-header {
    padding: 18px 24px 19px;
  }

  .manage-images-drawer .ant-drawer-content-wrapper {
    width: 500px !important;
  }

  .manage-images-drawer .upload-rules {
    font-size: 13px;
    top: 75px;
  }

  .manage-images-drawer .hide-drawer {
    margin-top: 40px;
  }

  .post-manage-images-wrap .rounded-button {
    margin: 0;
  }

  .sortable-list-tab {
    grid-template-columns: 109px 1fr;
  }
}

.posts-group{
    min-height: 300px;
}

.posts-group + .posts-group{
    margin-top: 40px;
}

.posts-group .posts-group-title{
    font: 700 14px/1.2em 'Titillium Web', sans-serif;
    margin-bottom: 20px;
}

.posts-group .posts-list-wrap .item-loader{
    min-height: 100px;
}


.timeline-message{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    background-color: #FFFFFF;
    box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.12);
    transition: all ease-out 0.25s;
    height: calc( 100vh - 310px );
    position: relative;
    border: 1px solid #EDEDED;
}

.timeline-posts{
    min-height: 400px;
}

.timeline-posts .card-list{
    padding: 0;
}

.timeline-wrap.container-margin {
    padding-top: 0;
}

@media (min-width: 768px) {
    .timeline-wrap.container-margin {
        border-top: 1px solid #e4e4e4;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
        padding-top: 40px;
    }
}

@media screen and (max-width: 767px) {

    .timeline-posts > .ant-row,
    .timeline-wrap > * > .ant-row-flex {
        margin: 0 !important;
    }

    .timeline-posts > .ant-row > .ant-col,
    .timeline-wrap > * > .ant-row-flex > .ant-col {
        padding: 0!important;
    }
}
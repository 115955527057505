.chat-list-item-wrap{
    padding: 10px 22px;
}

.chat-list-item{
    display: flex;
}

.chat-list-item .date{
    font-size: 11px;
    line-height: 1.4em;
}

.chat-list-item .state{
    display: flex;
    margin: 0 2px;
}

.chat-list-item .row:first-child{
    display: flex;
    align-items: center;
}

.chat-list-item .not-read{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
}

.chat-list-item .not-read:before{
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;

}


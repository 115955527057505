.main-bar-mobile{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 52px;
}

.main-bar-mobile .bar-actions{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: inherit;
}

.main-bar-mobile .bar-actions li{
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 28px;
}

.main-bar-mobile .bar-actions button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}

.main-bar-mobile .bar-create-post{
    font-size: 12px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
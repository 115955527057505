.following-page h2,
.followers-page h2 {
    font-size: 16px;
    text-align: center;
    padding-bottom: 12px;
    margin-top: 7px;
    margin-bottom: 0;
}

.followers-page .ant-col {
    min-height: 230px;
}

.followers-page .follow-list-wrap {
    min-height: 400px;
    max-height: initial;
}

.follower-message,
.following-tabs-message {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

@media (min-width: 768px) {
    .following-page h2,
    .followers-page h2 {
        font-size: 24px;
        padding-bottom: 20px;
        margin-top: 0;
    }

    .following-page .back-button,
    .followers-page .back-button {
        display: flex;
    }
}
.card-hero{
    padding: 11px 10px;
}

.card-hero{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
}

.card-hero .card-menu{
    display: flex;
    align-items: center;
}

.card-hero .card-menu button{
    padding: 0;
    border: none;
}

.card-hero .card-menu button i{
    font-size: 25px;
}

@media (min-width: 768px) {
    .card-hero{
        padding: 14px 18px 15px 20px;
    }

    .card-hero .card-menu button i{
        font-size: 30px;
    }
}
.card-preview-holder{
    align-items: stretch;
}

.card-preview{
    display: flex;
}

.card-preview .badge{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    font-size: 16px;
    color: #FFF;
    padding: 3px 4px 2px;
    border-radius: 0 0 10px 0;
}

.card-preview.news{
    border-width: 1px;
    border-style: solid;
    padding: 1px;
}


.card-preview > *{
    background-color: #efefef;
    display: block;
    width: 100%;
    position: relative;
}

.card-preview > button{
    padding: 0;
    border: none;
    text-align: left;
    cursor: pointer;
}

.card-preview .card-image{
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
}

.card-preview .card-image img{
    position: absolute;
    width: 100%;
}

.card-preview .card-data{
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-preview.loading .card-data{
    background-color: rgba(0, 0, 0, 0.5);
}

.card-preview .item-loader{
    color: #FFF;
    font-size: 34px;
}

.card-preview.loading .card-data,
.card-preview a:hover .card-data{
    opacity: 1;
}

.card-preview .card-data h3{
    color: #ffffff;
    padding: 0 10px;
    text-align: center;
}

.card-preview .card-counters{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #FFF;
}

.card-preview .card-counters li{
    display: flex;
    align-items: center;
}

.card-preview .card-counters li + li{
    margin-left: 12px;
}

.card-preview .card-counters span{
    font-size: 11px;
    font-weight: bold;
    margin-left: 3px;
}

.card-preview .card-counters .anticon{
    font-size: 16px;
}

.card-preview .card-more-images,
.card-preview .card-with-attachments{
    background-color: rgba(255, 255, 255, 0.8);
    right: 10px;
    position: absolute;
    padding: 4px;
    font-size: 22px;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    line-height: 1em;
}

.card-preview .card-more-images{
    top: 10px;
}

.card-preview .card-with-attachments{
    bottom: 10px;
}

@media (max-width: 767px) {

    .card-preview-holder{
        padding-left: 0 !important;
        padding-bottom: 0 !important;
    }

    .card-preview .card-more-images,
    .card-preview .card-with-attachments {
        font-size: 14px;
    }
}

@media (min-width: 768px) {

    .card-preview .badge{
        font-size: 24px;
        padding: 9px 13px 6px;
        border-radius: 0 0 10px 0;
    }

    .card-preview.news{
        border-width: 3px;
        padding: 3px;
    }

    .card-preview .card-counters .anticon{
        font-size: 20px;
    }

    .card-preview .card-counters span{
        font-size: 13px;
    }
}
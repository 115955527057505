.post-update {
    min-height: 300px;
}

.post-update h2 {
    display: none;
}

.post-update h4 {
    margin-top: 30px;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
}

.post-update .post-update-details h4 {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .post-update h2 {
        display: block;
    }

    .post-update h4 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .post-update .post-update-details h4 {
        margin-bottom: 10px;
    }
}

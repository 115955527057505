.follow-list-wrap{
    padding: 20px 0;
    max-height: 300px;
}

.follow-list-wrap .item-loader {
    margin: 15px 0;
}

.likes-modal .follow-list{
    margin-bottom: 30px;
}

.likes-modal .ant-modal-body{
    overflow: auto;
}

.following-page .follow-list li,
.followers-page .follow-list li {
    padding: 5px 0;
}

.follow-list {
    padding: 0 10px;
    margin: 0;
    list-style: none;
    min-width: 300px;
}

.follow-list li {
    padding: 10px 24px;
    display: flex;
    align-items: center;
}

.follow-list li:first-child {
    padding-top: 0;
}

.follow-list li:last-child {
    padding-bottom: 0;
}

.follow-list .site-label-text-color {
    opacity: 0.8;
    font-size: 12px;
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .following-page .follow-list li,
    .followers-page .follow-list li {
        padding: 10px 0;
    }

    .follow-list {
        padding: 0;
    }

    .follow-list .site-label-text-color {
        font-size: 14px;
    }
}

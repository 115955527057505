.chat-message-reply .message-text,
.chat-message{
    display: flex;
    font-size: 13px;
    line-height: 1.4em;
}

.group .chat-message.no-avatar:not(.my-message){
    padding-left: 45px;
}

.chat-message.my-message{
    justify-content: flex-end;
}

.chat-message-avatar-wrap{
    display: flex;
    align-items: flex-end;
    margin-right: 13px;
}

.chat-message-content-wrap{
    max-width: 75%;
}

.chat-message-author{
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 1.2em;
}

.chat-message-content{
    padding: 10px 20px 12px;
    border-radius: 16px;
    border: 1px solid transparent;
    white-space: pre-line;
    overflow: hidden;
}

.chat-message-status{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chat-message-status .state{
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.chat-message-status .state .anticon{
    font-size: 16px;
}

.chat-message-status .date {
    font-size: 12px;
}

.chat-message-reply{
    display: flex;
    flex: 1;
    transition: all ease-out 0.3s;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
}

.chat-message .chat-message-reply{
    height: initial;
    margin-bottom: 5px;
}

.chat-message-reply .action button{
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 32px;
    cursor: pointer;
}

.chat-message-reply .action .anticon{
    font-size: 12px;
}

.chat-message-reply h5{
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 3px;
    line-height: 1.2em;
}

.chat-message-reply .message-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.2em;
    display: block;
}

.chat-message-reply .message{
    border-left-width: 2px;
    border-left-style: solid;
    padding-left: 7px;
    flex: 1;
    min-width: 1px;
    margin-right: 36px;
}
.notification-list{
    list-style: none;
    padding: 0;
    min-height: 90px;
}

.notification-list .ant-avatar{
    display: flex;
    align-items: center;
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
}

.notification-list .post-notification > button{
    padding: 10px 20px;
}

.notification-list .post-notification .avatar-label{
    font-weight: 400;
    line-height: 1.4em;
    font-size: 13px;
    text-align: left;
}

.notification-list .post-notification .avatar-label a{
    font-weight: 600;
}

.notification-list .post-notification .right-side{
    margin-left: 15px;
}

.notification-list button{
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%;
    cursor: pointer;
    outline: none;
}

.notification-list .not-read{
    position: relative;
}

.notification-list .not-read:before{
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 8px;
    top: 50%;
    margin-top: -3px;
}

.notification-list .notifications-empty{
    text-align: center;
    padding: 30px 0;
    height: calc(85vh - 150px);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.notification-list .notifications-empty h3{
    font-size: 14px;
    margin: 0;
}

.notification-filter {
    padding: 10px 0 0;
    margin-bottom: -10px;
    display: flex;
    justify-content: center;
}

.notification-filter .ant-radio-button-wrapper-checked:focus-within,
.notification-filter .ant-radio-button-wrapper-checked:focus{
    outline: none;
}

.notification-filter .ant-radio-button-wrapper{
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    box-shadow: none;
}

.notification-filter .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    border: none;
    box-shadow: none;
}

.notification-filter .ant-radio-button-wrapper:not(:first-child)::before{
    display: none !important;
}

@media (min-width: 768px) {
    .notification-filter .ant-radio-button-wrapper{
        font-size: 16px;
    }

    .notification-list .ant-avatar{
        width: 50px !important;
        height: 50px !important;
    }

    .notification-list .post-notification > button{
        padding: 15px 20px;
    }

    .notification-filter {
        padding: 10px 0;
        margin-bottom: 0;
    }
}
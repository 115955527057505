.card-list {
    flex-wrap: wrap;
    display: flex;
}

@media (min-width: 768px) {
    .card-list {
        padding: 0;
    }
}

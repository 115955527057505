.user-request-modal .ant-modal-footer{
    display: flex;
    justify-content: space-between;
    border: none !important;
}

.user-request-modal.modal-actions .ant-modal{
    max-width: 520px !important;
}

.user-request-modal .ant-modal-body{
    max-height: initial !important;
}

.user-request-modal .user-request{
    padding: 14px 16px 15px !important;
}

.user-request-modal .intro{
    margin-bottom: 25px;
}

.user-request-modal .intro h3{
    margin: 0;
}

.user-request-modal .result{
    padding: 40px;
    text-align: center;
    font-size: 24px;
}

.user-request-modal.sended .ant-modal-footer button{
    margin: 0 auto;
}
.login-form .ant-form-item-required::before{
    display: none;
}


@media (min-width: 768px) {
    .login-form .form-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.login-form .ant-input-affix-wrapper .ant-input:not(:first-child){
    padding-left: 35px;
}

.notification-drawer .ant-drawer-content-wrapper{
    width: 450px !important;
}

.notification-drawer .ant-drawer-wrapper-body{
    overflow: hidden !important;
}

.notification-drawer .ant-drawer-body{
    padding: 0;
    overflow: auto;
    max-height: calc(100vh - 60px);
}

@media screen and (max-width: 479px) {
    .notification-drawer .ant-drawer-content-wrapper{
        width: 100% !important;
    }

}
.comments-drawer .ant-drawer-content-wrapper {
    width: 450px !important;
}

.comments-drawer .ant-drawer-wrapper-body {
    overflow: hidden !important;
}

.comments-drawer .ant-drawer-body {
    padding: 0;
    overflow: auto;
    max-height: calc(100vh - 60px);
    min-height: 350px;
}

.comments-drawer .add-comment {
    padding: 10px 12px 11px 13px;
    border-bottom: 1px solid #e4e4e4;
}

.comments-drawer .comments-list-wrap {
    padding: 20px 12px;
}

.comments-drawer .comments-list li + li {
    margin-top: 20px;
}

@media screen and (max-width: 479px) {
    .comments-drawer .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .comments-drawer .comments-list-wrap .ant-avatar {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
        font-size: 14px !important;
    }
}

@media (min-width: 768px) {

    .comments-drawer .add-comment {
        padding: 15px 20px;
    }

    .comments-drawer .comments-list-wrap {
        padding: 20px;
    }

    .comments-drawer .ant-drawer-header {
        padding: 18px 24px 19px;
    }
}

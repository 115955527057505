.card-preview-row-holder {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #dddddd;
}

.card-preview-row-holder .ant-avatar-square {
    border-radius: 0;
}

.card-preview-row-holder:first-child .card-preview-row {
    padding-top: 0;
}

.card-preview-row {
    padding: 10px 0 5px;
}

.card-preview-row .card-author {
    font-size: 13px;
    line-height: 1em;
    margin-bottom: 3px;
}

.card-preview-row .card-author .anticon {
    font-size: 10px;
    margin-right: 3px;
}

.card-preview-row .card-data a {
    display: block;
    border-color: inherit;
}

.card-preview-row .avatar-photo {
    margin-right: 20px;

}

.card-preview-row .avatar-data {
    padding: 0;
}

.card-preview-row .avatar-photo > * {
    border-color: inherit;
}

.card-preview-row .avatar-photo .ant-avatar {
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
}

.card-preview-row.news .avatar-photo .ant-avatar{
    padding: 1px;
}

.card-preview-row .card-date {
    font-size: 11px;
}

.card-preview-row .card-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1px;
    flex: 1;
}

.card-preview-row .card-counters ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    font-weight: bold;
    font-size: 11px;
}

.card-preview-row .card-counters li {
    display: flex;
    align-items: center;
}

.card-preview-row .card-counters li + li {
    margin-left: 12px;
}

.card-preview-row .company-children-hover-color > * {
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-preview-row .card-counters .anticon {
    font-size: 16px;
}

.card-preview-row .card-counters span {
    margin-left: 3px;
}

.card-preview-row .card-title {
    font-size: 14px;
    line-height: 1.4em;
    font-weight: bold;
    margin: 0;
}

.card-preview-row .card-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    font-size: 14px;
}

.card-preview-row .card-info {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

@media (min-width: 768px) {

    .card-preview-row-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .card-preview-row {
        padding: 20px 0;
    }

    .card-preview-row .card-data {
        font-size: 13px;
    }

    .card-preview-row .card-date {
        font-size: 13px;
    }

    .card-preview-row .card-counters ul {
        font-size: 13px;
    }

    .card-preview-row .card-counters .anticon {
        font-size: 20px;
    }
}

@media (min-width: 1024px) {
    .card-preview-row .card-data {
        width: 62%;
    }

    .card-preview-row .card-info {
        width: 35%;
    }

    .card-preview-row {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    .card-preview-row .card-data {
        display: flex;
        width: 62%;
        flex-wrap: wrap;
    }

    .card-preview-row .card-info {
        width: 38%;
        align-items: center;
        padding-left: 20px;
        margin-top: 0;
    }
}

.chat-messages-header.loading{
    display: flex;
    justify-content: center;
}

.chat-messages-header .avatar-data .avatar-label a,
.chat-messages-header .avatar-text{
    font-size: 12px !important;
}

.chat-settings-button{
    font-size: 24px;
    margin-left: 20px;
}

.chat-messages-header .back-button{
    position: initial;
    border: none;
    margin-left: -15px;
    margin-right: 5px;
}

@media (min-width: 768px) {
    .chat-messages-header .back-button{
        display: none;
    }

    .chat-messages-header{
        background: transparent !important;
    }

    .chat-messages-header .avatar-data .avatar-label a,
    .chat-messages-header .avatar-text{
        font-size: 13px !important;
    }

}
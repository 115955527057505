.hashtag-header {
    border-bottom: 1px solid #e9ebee;
}

.hashtag-header h1 {
    font: 400 16px/1.2em 'Titillium Web', sans-serif;
    margin: 0;
}

.hashtag-header-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hashtag-header-photo {
    display: flex;
    justify-content: center;
}

.hashtag-header-photo .ant-avatar {
    font-size: 40px !important;
}

.hashtag-header-actions .hashtag-header-but {
    font: bold 12px/1.3em 'Titillium Web', sans-serif;
    white-space: nowrap;
    border: none;
    height: 30px;
    min-height: auto;
    padding: 0 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    position: relative;
}

.hashtag-header-actions {
    display: flex;
    align-items: center;
}

.hashtag-header-statistics {
    display: flex;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    flex-direction: row;
    margin: 0;
    padding: 13px 0;
    list-style: none;
}

.hashtag-header-statistics a{
    color: inherit;
}

.hashtag-header-statistics a,
.hashtag-header-statistics a b{
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.hashtag-header-statistics li + li{
    margin-left: 68px;
}

.hashtag-header-statistics .site-bold-color {
    display: block;
    font-size: 14px;
}

@media (max-width: 767px) {
    .hashtag-header-data h1 {
        padding: 15px 0 11px;
    }
}

@media (min-width: 768px) {
    .hashtag-header-actions .hashtag-header-but {
        font: bold 14px/1.3em 'Titillium Web', sans-serif;
        padding: 0 20px;
        min-width: 150px;
        height: 40px;
    }

    .hashtag-header {
        padding-bottom: 45px;
        min-height: 150px;
    }

    .hashtag-header h1 {
        font: 400 24px/1.2em 'Titillium Web', sans-serif;
    }

    .hashtag-header-data {
        flex-direction: row;
        margin-bottom: 20px;
    }

    .hashtag-header-actions > * {
        margin-left: 15px;
    }

    .hashtag-header-statistics {
        text-align: left;
        font-size: 14px;
        justify-content: flex-start;
        padding: 0;
    }

    .hashtag-header-statistics .site-bold-color {
        display: inline;
    }

    .hashtag-header-statistics li + li {
        margin-left: 40px;
    }
}

.registration-form .form-actions{
    display: flex;
    justify-content: center;
}

.registration-form .ant-form-item-required span{
    float: left;
    line-height: 1.5em;
}

.registration-form .ant-form-item-required::before {
    margin-left: 2px;
}

.registration-form .form-icon{
    position: relative;
    top: 1px;
}

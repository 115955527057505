.hashtag {
    min-height: 300px;
}

.hashtag .hashtag-posts {
    margin-bottom: 50px;
    min-height: 320px;
}

.hashtag .hashtag-posts .card-list {
    padding: 10px 0 0;
}

.hashtag .empty-hashtag {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.hashtag-posts-actions {
    padding: 10px 0;
}

.hashtag-posts-actions .posts-view-type {
    max-width: 290px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .hashtag-posts-actions .posts-view-type {
        max-width: 100%;
        margin: 0;
        display: flex;
    }
}

.post-update-details .ant-btn + .ant-btn {
    margin-left: 20px !important;
}

.post-update-details .ant-form-item-label {
    padding-bottom: 4px;
}

.post-update-details .ant-input {
    box-shadow: none;
    border-radius: 8px;
}

.post-update-details .site-label-text-color {
    line-height: 1.5em;
    margin-bottom: 2px;
}

@media (min-width: 768px) {
    .post-update-details .ant-form-item {
        margin-bottom: 15px;
    }
}

.container-margin {
    padding-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 52px;
}

.container-margin-short-top {
    padding-top: 10px;
}

@media (min-width: 768px) {
    .container-margin{
        margin-bottom: 0;
    }

    .container-margin-short-top {
        padding-top: 40px;
    }
}

@media (min-width: 1024px) {
    .container-margin {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

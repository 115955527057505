.add-comment {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.add-comment .ant-mentions{
    padding: 6px 20px 5px;
    max-height: 80px;
    min-height: 32px !important;
    border-radius: 16px;
    overflow: auto;
}

.add-comment .mention-result{
    margin-top: -2px;
}

.add-comment .mention-result.after{
    margin-top: 2px;
}

.add-comment .ant-mentions textarea {
    display: flex;
    flex: auto;
    flex-direction: column;
    font: 400 13px/1.4em 'Titillium Web', sans-serif;
    outline: 0;
    resize: none;
    border: 0;
    background-color: transparent;
    height: 1.4em;
    min-height: 1.4em;
    border-radius: initial;
    overflow: hidden;
    padding: 0;
}

.add-comment button i{
    position: relative;
    top: 1px;
}

.add-comment button{
    color: #FFF;
    outline: 0;
    border: 0;
    padding: 0;
    cursor: pointer;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    height: 32px;
    font-family: inherit;
    font-size: 12px;
}
.add-comment textarea::placeholder {
    line-height: 1.5em;
}

.add-comment textarea::-moz-placeholder {
    line-height: 1.5em;
}

.add-comment textarea::-webkit-input-placeholder {
    line-height: 1.5em;
}

.add-comment button {
    color: #fff;
    outline: 0;
    border: 0;
    padding: 0;
    cursor: pointer;
    min-width: 32px !important;
    min-height: 32px !important;
    border-radius: 50%;
    height: 32px;
    font-family: inherit;
    font-size: 12px;
}

.add-comment button:hover {
    opacity: 0.7;
}

.add-comment button[disabled]:hover {
    opacity: 1;
}

.add-comment .comment-field-wrap{
    width: 100%;
    margin-right: 6px;
}

.add-comment .ant-mentions {
    margin-bottom: 0;
    outline: none;
    border: none;
    box-shadow: none;
    width: 100%;
}

.add-comment .ant-mentions textarea:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

@media (min-width: 768px) {
    .add-comment .ant-mentions textarea {
        font: 400 14px/1.5em 'Titillium Web', sans-serif;
        padding: 0;
        height: 1.5em;
        min-height: 1.5em;
    }

    .add-comment .ant-mentions{
        padding: 5px 20px 4px;
    }
}

.profile-tabs {
    margin-top: 20px;
}

.profile-tabs .ant-tabs-nav .ant-tabs-tab-active {
    color: #333333;
}

.profile-tabs .ant-tabs-ink-bar {
    display: none !important;
}

.profile-tabs .ant-tabs .ant-tabs-left-bar {
    display: none;
    border-color: transparent;
}

.profile-tabs .ant-tabs-left-bar .ant-tabs-tab {
    font-weight: bold;
    font-size: 14px;
    min-width: 225px;
    text-align: left;
    margin: 0;
    padding: 8px 24px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.profile-tabs .ant-col-24.ant-form-item-label {
    padding: 0 !important;
    line-height: 1em;
    font-size: 13px;
}

.profile-tabs .ant-form-item-required span {
    float: left;
}

.profile-tabs .ant-input:not([disabled]) {
    background-color: transparent;
}

.profile-tabs .ant-input {
    border-radius: 8px;
}

.profile-tabs .site-label-text-color {
    line-height: 1.5em;
    margin-bottom: 2px;
}

.profile-tabs .ant-form-item-control {
    line-height: 1em;
}

.profile-tabs .ant-form-item-required::before {
    margin-left: 4px;
}

.profile-tabs .ant-select-selection__rendered {
    margin-left: 15px;
    margin-right: 30px;
}

.profile-tabs .ant-select-arrow {
    right: 12px;
    font-size: 12px;
    color: #333;
}

.profile-tabs .ant-select-selection--single {
    box-shadow: none;
    border-radius: 8px;
}

.profile-tabs .ant-tabs-content {
    min-height: 500px;
    border-color: transparent;
    padding: 5px 10px 30px;
}

.profile-tabs .ant-upload .ant-avatar {
    cursor: pointer;
}

.profile-tabs .ant-row:last-child {
    margin-bottom: 0;
}

.profile-avatar {
    margin-bottom: 10px;
}

.profile-tabs .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.settings .ant-calendar-picker{
    margin-top: 3px;
}

.profile-tabs .ant-calendar-picker{
    width: 100%;

}

.profile-tabs .form-actions button + button {
    margin-left: 20px;
}

.profile-tabs .no-dealer{
    text-align: center;
}

@media (max-width: 767px) {
    .profile-tabs .ant-upload .ant-avatar {
        width: 76px !important;
        height: 76px !important;
    }
}

@media (min-width: 768px) {

    .profile-tabs {
        margin-top: 0;
    }

    .profile-tabs .ant-tabs .ant-tabs-left-bar {
        display: block;
    }

    .profile-tabs .ant-select-selection--single {
        height: 40px;
    }

    .profile-tabs .ant-select-selection__rendered {
        line-height: 40px;
    }

    .profile-tabs .ant-form-item {
        margin-bottom: 15px;
    }

    .profile-avatar {
        margin-bottom: 25px;
    }

    .profile-tabs .form-actions {
        justify-content: flex-end;
    }

    .profile-tabs .ant-tabs .ant-tabs-left-content {
        padding-left: 24px;
    }

    .profile-tabs .ant-select-arrow {
        right: 16px;
        font-size: 14px;
    }

    .profile-tabs .ant-tabs-content {
        padding: 0;
    }
}
